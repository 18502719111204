import { useQuery } from 'react-query';
import get from 'lodash/get';
import requestGql, { querySetting } from 'utils/requestGql';
import { GET_CMS } from 'containers/CustomerService/gql/cmsQuery';
import { getFaqsList } from 'containers/CustomerService/api';

export const getCmsQuery = id => requestGql(GET_CMS(id));

export const CMS_QUERY_KEY = {
  GET: 'cms.get',
  GET_FAQS: 'cms.getFaqsList',
};

export const useGetCms = id =>
  useQuery(
    id && [CMS_QUERY_KEY.GET, id],
    () => getCmsQuery(id).then(response => get(response, 'cmsBlocks.items[0]')),
    querySetting,
  );

export const useGetFaqsList = payload =>
  useQuery([CMS_QUERY_KEY.GET_FAQS], () => getFaqsList(payload).then(res => res), {
    ...querySetting,
    refetchOnMount: true,
  });
