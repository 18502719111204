/*
 * Generic App Messages
 *
 * This contains all generic messages that could be used in other containers or components
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.generic';

export default defineMessages({
  agreeEntry: {
    id: `${scope}.agreeEntry`,
    defaultMessage: 'Agree',
  },
  rejectEntry: {
    id: `${scope}.rejectEntry`,
    defaultMessage: 'Exit',
  },
  duplicateItems: {
    id: `${scope}.duplicateItems`,
    defaultMessage: 'Duplicate Items',
  },
  emptyCheckoutNotice: {
    id: `${scope}.emptyCheckoutNotice`,
    defaultMessage: 'There are no purchases to checkout',
  },
  duplicateCheckoutNotice: {
    id: `${scope}.duplicateCheckoutNotice`,
    defaultMessage: 'Please delete the duplicate products in the shopping cart',
  },
  copy: {
    id: `${scope}.copy`,
    defaultMessage: 'Copy',
  },
  backToHomePage: {
    id: `${scope}.backToHomePage`,
    defaultMessage: 'Go Back',
  },
  confirmRemovalOfItem: {
    id: `${scope}.confirmRemovalOfItem`,
    defaultMessage: 'Confirm removal of item',
  },
  close: {
    id: `${scope}.close`,
    defaultMessage: 'Close',
  },
  back: {
    id: `${scope}.back`,
    defaultMessage: 'Back',
  },
  goBack: {
    id: `${scope}.goBack`,
    defaultMessage: 'Go Back',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel',
  },
  moveToWishlist: {
    id: `${scope}.moveToWishlist`,
    defaultMessage: 'move to wishlist',
  },
  finish: {
    id: `${scope}.finish`,
    defaultMessage: 'Finish',
  },
  ok: {
    id: `${scope}.ok`,
    defaultMessage: 'OK',
  },
  confirm: {
    id: `${scope}.confirm`,
    defaultMessage: 'Confirm',
  },
  confirmSelection: {
    id: `${scope}.confirmSelection`,
    defaultMessage: 'Confirm',
  },
  update: {
    id: `${scope}.update`,
    defaultMessage: 'Update',
  },
  removeItems: {
    id: `${scope}.removeItems`,
    defaultMessage: 'Remove Items',
  },
  deleteSelected: {
    id: `${scope}.deleteSelected`,
    defaultMessage: 'Delete Selected',
  },
  select: {
    id: `${scope}.select`,
    defaultMessage: 'Select',
  },
  selectAll: {
    id: `${scope}.selectAll`,
    defaultMessage: 'Select All',
  },
  processing: {
    id: `${scope}.processing`,
    defaultMessage: 'Processing',
  },
  view: {
    id: `${scope}.view`,
    defaultMessage: 'View',
  },
  viewAll: {
    id: `${scope}.viewAll`,
    defaultMessage: 'View All',
  },
  checkDetails: {
    id: `${scope}.checkDetails`,
    defaultMessage: 'Details',
  },
  next: {
    id: `${scope}.next`,
    defaultMessage: 'Next',
  },
  loading: {
    id: `${scope}.loading`,
    defaultMessage: 'Loading',
  },
  ourTeamHasBeenNotified: {
    id: `${scope}.ourTeamHasBeenNotified`,
    defaultMessage:
      "Our team has been notified. If you'd like to help us you can tell us what happened below.",
  },
  reportFeedback: {
    id: `${scope}.reportFeedback`,
    defaultMessage: 'Report Report',
  },
  default: {
    id: `${scope}.default`,
    defaultMessage: 'Default',
  },
  // Address related messages
  firstname: {
    id: `${scope}.firstname`,
    defaultMessage: 'First Name',
  },
  lastname: {
    id: `${scope}.lastname`,
    defaultMessage: 'Last Name',
  },
  phoneNumber: {
    id: `${scope}.phoneNumber`,
    defaultMessage: 'Phone Number',
  },
  address: {
    id: `${scope}.address`,
    defaultMessage: 'Address',
  },
  address1Default: {
    id: `${scope}.address1Default`,
    defaultMessage: 'Address',
  },
  address2Default: {
    id: `${scope}.address2Default`,
    defaultMessage: 'Address Additional Line',
  },
  uploadPhoto: {
    id: `${scope}.uploadPhoto`,
    defaultMessage: '* Upload Photo',
  },
  uploadTakePhoto: {
    id: `${scope}.uploadTakePhoto`,
    defaultMessage: 'Upload / Take Photo',
  },
  uploadTakePhotoOrVideo: {
    id: `${scope}.uploadTakePhotoOrVideo`,
    defaultMessage: 'Upload / Take Photo / Video',
  },
  reUploadTakePhotoOrVideo: {
    id: `${scope}.reUploadTakePhotoOrVideo`,
    defaultMessage: 'Re-upload / Take Photo / Video',
  },
  attachmentRestrictions: {
    id: `${scope}.attachmentRestrictions`,
    defaultMessage:
      'The maximum allowed file size is {size} per attachment. The accepted file formats are {formats}.',
  },
  imageLimitRestriction: {
    id: `${scope}.imageLimitRestriction`,
    defaultMessage: 'You can only add up to {limit} images',
  },
  invalidFileFormat: {
    id: `${scope}.invalidFileFormat`,
    defaultMessage: 'File format is not allowed, please check your selection.',
  },
  imageSizeRestriction: {
    id: `${scope}.imageSizeRestriction`,
    defaultMessage: 'Selected image(s) are too big, please use smaller images.',
  },
  regionDefault: {
    id: `${scope}.regionDefault`,
    defaultMessage: 'State / Province / Region',
  },
  cityDefault: {
    id: `${scope}.cityDefault`,
    defaultMessage: 'City / Town',
  },
  districtDefault: {
    id: `${scope}.districtDefault`,
    defaultMessage: 'District / Neighbourhood',
  },
  postalDefault: {
    id: `${scope}.postalDefault`,
    defaultMessage: 'ZIP Code / Postal Code',
  },

  // Custom form labels
  state: {
    id: `${scope}.state`,
    defaultMessage: 'State',
  },
  district: {
    id: `${scope}.district`,
    defaultMessage: 'District',
  },
  postalCode: {
    id: `${scope}.postalCode`,
    defaultMessage: 'Postal Code',
  },
  zipCode: {
    id: `${scope}.zipCode`,
    defaultMessage: 'ZIP Code',
  },

  // Order related messages
  orderSummary: {
    id: `${scope}.orderSummary`,
    defaultMessage: 'Order Summary',
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: 'Name',
  },
  pvc: {
    id: `${scope}.pvc`,
    defaultMessage: '{total}PV',
  },
  totalColon: {
    id: `${scope}.totalColon`,
    defaultMessage: 'Total:',
  },
  PVColon: {
    id: `${scope}.PVColon`,
    defaultMessage: 'PV total:',
  },
  grandTotal: {
    id: `${scope}.grandTotal`,
    defaultMessage: 'Grand Total',
  },
  shippingAmount: {
    id: `${scope}.shippingAmount`,
    defaultMessage: 'Shipping Fee',
  },
  addToCart: {
    id: `${scope}.addToCart`,
    defaultMessage: 'Add to Cart',
  },
  buyNow: {
    id: `${scope}.buyNow`,
    defaultMessage: 'Buy Now',
  },
  orderNumberColon: {
    id: `${scope}.orderNumberColon`,
    defaultMessage: 'Order Number: {orderId}',
  },

  // Empty pages related messages
  startShopping: {
    id: `${scope}.startShopping`,
    defaultMessage: 'Start Shopping',
  },
  noArticles: {
    id: `${scope}.noArticles`,
    defaultMessage: 'No articles',
  },
  tapToStartShopping: {
    id: `${scope}.tapToStartShopping`,
    defaultMessage: 'Tap the button to start shopping',
  },
  wrongPhoneNumber: {
    id: `${scope}.wrongPhoneNumber`,
    defaultMessage: 'Wrong Phone Number',
  },
  home: {
    id: `${scope}.home`,
    defaultMessage: 'Home',
  },
  sharing: {
    id: `${scope}.sharing`,
    defaultMessage: 'Sharing',
  },
  category: {
    id: `${scope}.category`,
    defaultMessage: 'Category',
  },
  cart: {
    id: `${scope}.cart`,
    defaultMessage: 'Cart',
  },
  account: {
    id: `${scope}.account`,
    defaultMessage: 'Account',
  },
  expand: {
    id: `${scope}.expand`,
    defaultMessage: 'Expand',
  },
  collapse: {
    id: `${scope}.collapse`,
    defaultMessage: 'Collapse',
  },
  optional: {
    id: `${scope}.optional`,
    defaultMessage: 'Optional',
  },
  orderNumber: {
    id: `${scope}.orderNumber`,
    defaultMessage: 'Order Number',
  },
  orderTime: {
    id: `${scope}.orderTime`,
    defaultMessage: 'Order Time',
  },
  fapiaoType: {
    id: `${scope}.fapiaoType`,
    defaultMessage: 'Invoice Type',
  },
  viewMore: {
    id: `${scope}.viewMore`,
    defaultMessage: 'View More',
  },
  fitLine: {
    id: `${scope}.fitLine`,
    defaultMessage: 'FitLine',
  },
  new: {
    id: `${scope}.new`,
    defaultMessage: 'New',
  },
  left: {
    id: `${scope}.left`,
    defaultMessage: 'Left',
  },
  actions: {
    id: `${scope}.actions`,
    defaultMessage: 'Actions',
  },
  recipient: {
    id: `${scope}.recipient`,
    defaultMessage: 'Recipient',
  },
  tag: {
    id: `${scope}.tag`,
    defaultMessage: 'Tag',
  },
  orderManage: {
    id: `${scope}.orderManage`,
    defaultMessage: 'My Order',
  },
  shippingAddress: {
    id: `${scope}.shippingAddress`,
    defaultMessage: 'Shipping Address',
  },
  wishlist: {
    id: `${scope}.wishlist`,
    defaultMessage: 'Wishlist',
  },
  logOut: {
    id: `${scope}.logOut`,
    defaultMessage: 'Log Out',
  },
  myAccount: {
    id: `${scope}.myAccount`,
    defaultMessage: 'My Account',
  },
  timeLeft: {
    id: `${scope}.timeLeft`,
    defaultMessage: 'Time Left ',
  },
  start: {
    id: `${scope}.start`,
    defaultMessage: 'Start',
  },
  days: {
    id: `${scope}.days`,
    defaultMessage: 'days',
  },
  hours: {
    id: `${scope}.hours`,
    defaultMessage: 'hours',
  },
  minutes: {
    id: `${scope}.minutes`,
    defaultMessage: 'minutes',
  },
  seconds: {
    id: `${scope}.seconds`,
    defaultMessage: 'seconds',
  },
  others: {
    id: `${scope}.others`,
    defaultMessage: 'Others',
  },
  pleaseChangeInfobeforeSubmit: {
    id: `${scope}.pleaseChangeInfobeforeSubmit`,
    defaultMessage: 'Please Change the Information before Submitting',
  },
  statusWaitting: {
    id: `${scope}.statusWaitting`,
    defaultMessage: 'Waitting',
  },
  pleaseInputInfoWithCare: {
    id: `${scope}.pleaseInputInfoWithCare`,
    defaultMessage: 'Please input all information with care',
  },
  frontOfID: {
    id: `${scope}.frontOfID`,
    defaultMessage: 'Front of ID Card',
  },
  backOfID: {
    id: `${scope}.backOfID`,
    defaultMessage: 'Back of ID Card',
  },
  contract: {
    id: `${scope}.contract`,
    defaultMessage: 'Contract',
  },
  pleaseAddBothFrontAndBack: {
    id: `${scope}.pleaseAddBothFrontAndBack`,
    defaultMessage: 'Please add both front and back pictures',
  },
  fullLegalName: {
    id: `${scope}.fullLegalName`,
    defaultMessage: 'Full legal name',
  },
  idNumber: {
    id: `${scope}.idNumber`,
    defaultMessage: '18-digit ID number',
  },
  companyName: {
    id: `${scope}.companyName`,
    defaultMessage: 'Company name',
  },
  businessLicense: {
    id: `${scope}.businessLicense`,
    defaultMessage: 'Business license',
  },
  businessLicenseNumber: {
    id: `${scope}.businessLicenseNumber`,
    defaultMessage: 'Business license number',
  },
  bankNamePlaceholder: {
    id: `${scope}.bankNamePlaceholder`,
    defaultMessage: 'Select bank name',
  },
  bankBranchNamePlaceholder: {
    id: `${scope}.bankBranchNamePlaceholder`,
    defaultMessage: 'Enter province and city',
  },
  receivingAccountName: {
    id: `${scope}.receivingAccountName`,
    defaultMessage: 'Receiving Account Name',
  },
  receivingAccountNumber: {
    id: `${scope}.receivingAccountNumber`,
    defaultMessage: 'Receiving Account Number',
  },
  previousUploads: {
    id: `${scope}.previousUploads`,
    defaultMessage: 'Previous Upload(s)',
  },
  statusPending: {
    id: `${scope}.statusPending`,
    defaultMessage: 'Under Review',
  },
  statusDeclined: {
    id: `${scope}.statusDeclined`,
    defaultMessage: 'Declined',
  },
  statusExpired: {
    id: `${scope}.statusExpired`,
    defaultMessage: 'Expired',
  },
  statusApproved: {
    id: `${scope}.statusApproved`,
    defaultMessage: 'Approved',
  },
  copySuccess: {
    id: `${scope}.copySuccess`,
    defaultMessage: 'Copy Success',
  },
  copyFailed: {
    id: `${scope}.copyFailed`,
    defaultMessage: 'Copy failed',
  },
  mobile: {
    id: `${scope}.mobile`,
    defaultMessage: 'Mobile',
  },
  ibeid: {
    id: `${scope}.ibeid`,
    defaultMessage: 'IBE Number',
  },
  status: {
    id: `${scope}.status`,
    defaultMessage: 'Status',
  },
  createdAt: {
    id: `${scope}.createdAt`,
    defaultMessage: 'Created At',
  },
  operation: {
    id: `${scope}.operation`,
    defaultMessage: 'Operation',
  },
  number: {
    id: `${scope}.number`,
    defaultMessage: 'ID',
  },
  pass: {
    id: `${scope}.pass`,
    defaultMessage: 'Pass',
  },
  reject: {
    id: `${scope}.reject`,
    defaultMessage: 'Reject',
  },
  rejectReason: {
    id: `${scope}.rejectReason`,
    defaultMessage: 'Reject Reason',
  },
  product: {
    id: `${scope}.product`,
    defaultMessage: 'All',
  },
  activity: {
    id: `${scope}.activity`,
    defaultMessage: 'Activity',
  },
  wechatMini: {
    id: `${scope}.wechatMini`,
    defaultMessage: 'Wechat miniProgram',
  },
  refreshNow: {
    id: `${scope}.refreshNow`,
    defaultMessage: 'Let go and refresh immediately',
  },
  refreshComplete: {
    id: `${scope}.refreshComplete`,
    defaultMessage: 'Complete',
  },
  shareQrCode: {
    id: `${scope}.shareQrCode`,
    defaultMessage: 'Share Qr Code',
  },
  pleaseSelectAnAddressFirst: {
    id: `${scope}.pleaseSelectAnAddressFirst`,
    defaultMessage: 'Address first',
  },
  noPaymentMethods: {
    id: `${scope}.noPaymentMethods`,
    defaultMessage: 'No payment methods available',
  },
  productInformationNotExist: {
    id: `${scope}.productInformationNotExist`,
    defaultMessage: 'Product information does not exist',
  },
  switchStore: {
    id: `${scope}.switchStore`,
    defaultMessage: 'Switch Store',
  },
  selectQuantity: {
    id: `${scope}.selectQuantity`,
    defaultMessage: 'select quantity',
  },
  checkoutFromFlagshipStore: {
    id: `${scope}.checkoutFromFlagshipStore`,
    defaultMessage: 'Checkout from flagship store',
  },
  recommendedProducts: {
    id: `${scope}.recommendedProducts`,
    defaultMessage: 'Recommended Products',
  },
  datePickerFormat: {
    id: `${scope}.datePickerFormat`,
    defaultMessage: 'MM YYYY Statistic',
  },
  paymentTime: {
    id: `${scope}.paymentTime`,
    defaultMessage: 'Payment Time',
  },
  shipDate: {
    id: `${scope}.shipDate`,
    defaultMessage: 'Ship date',
  },
  receivedDate: {
    id: `${scope}.receivedDate`,
    defaultMessage: 'Received date',
  },
  beforeDateRefund: {
    id: `${scope}.beforeDateRefund`,
    defaultMessage: 'Before {time} you can choose a refund',
  },
  confirmOrder: {
    id: `${scope}.confirmOrder`,
    defaultMessage: 'Confirm Order',
  },
  miniprogram: {
    id: `${scope}.miniprogram`,
    defaultMessage: 'miniProgram',
  },
  FEIDEKAI: {
    id: `${scope}.FEIDEKAI`,
    defaultMessage: 'FEIDEKAI',
  },
  time: {
    id: `${scope}.time`,
    defaultMessage: 'Date',
  },
  paymentMethod: {
    id: `${scope}.paymentMethod`,
    defaultMessage: 'Payment Method',
  },
  all: {
    id: `${scope}.all`,
    defaultMessage: 'All',
  },
  level: {
    id: `${scope}.level`,
    defaultMessage: 'Level',
  },
  levelWithParams: {
    id: `${scope}.levelWithParams`,
    defaultMessage: 'Level{level}',
  },
  byMonth: {
    id: `${scope}.byMonth`,
    defaultMessage: 'By Month',
  },
  customizedTime: {
    id: `${scope}.customizedTime`,
    defaultMessage: 'Customized Time',
  },
  past3Months: {
    id: `${scope}.past3Months`,
    defaultMessage: 'Past 3 Months',
  },
  past6Months: {
    id: `${scope}.past6Months`,
    defaultMessage: 'Past 6 Months',
  },
  pastYear: {
    id: `${scope}.pastYear`,
    defaultMessage: 'Past Year',
  },
  datePickerMaxPeriodWithParams: {
    id: `${scope}.datePickerMaxPeriodWithParams`,
    defaultMessage: '*Records with a maximum span of {count} years can be searched',
  },
  fromTo: {
    id: `${scope}.fromTo`,
    defaultMessage: 'To',
  },
  startTime: {
    id: `${scope}.startTime`,
    defaultMessage: 'Start Time',
  },
  endTime: {
    id: `${scope}.endTime`,
    defaultMessage: 'End Time',
  },
  monthWithParams: {
    id: `${scope}.monthWithParams`,
    defaultMessage: '{month}',
  },
  weChatPay: {
    id: `${scope}.weChatPay`,
    defaultMessage: 'WeChat Pay',
  },
  alipay: {
    id: `${scope}.alipay`,
    defaultMessage: 'Alipay',
  },
  creditPoints: {
    id: `${scope}.creditPoints`,
    defaultMessage: 'Credit Points',
  },
  pleaseInputWithText: {
    id: `${scope}.pleaseInputWithText`,
    defaultMessage: 'Please Input {text}',
  },
  forUserCreation: {
    id: `${scope}.forUserCreation`,
    defaultMessage: 'For user creation',
  },
  detailedAddress: {
    id: `${scope}.detailedAddress`,
    defaultMessage: 'Building, house number, etc.',
  },
  billingAddress: {
    id: `${scope}.billingAddress`,
    defaultMessage: 'Billing Address',
  },
  billingAddressDesc: {
    id: `${scope}.billingAddressDesc`,
    defaultMessage: 'Billing Address (This address will be used for billing)',
  },
  billingAddress_confirm_dialog_title: {
    id: `${scope}.billingAddress_confirm_dialog_title`,
    defaultMessage: 'Do you confirm to modify the information?',
  },
  billingAddress_confirm_dialog_content: {
    id: `${scope}.billingAddress_confirm_dialog_content`,
    defaultMessage:
      'The "Billing Address" information is only used for billing and will not affect your "Shipping Address"',
  },
  confirm_the_changes: {
    id: `${scope}.confirm_the_changes`,
    defaultMessage: 'Confirm',
  },
  wait_data_to_load: {
    id: `${scope}.wait_data_to_load`,
    defaultMessage: 'Please wait for the data to load.',
  },
  confirm_and_submit: {
    id: `${scope}.confirm_and_submit`,
    defaultMessage: 'Submit',
  },
  submit_application: {
    id: `${scope}.submit_application`,
    defaultMessage: 'Submit',
  },
});
