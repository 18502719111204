/*
 * Authorization Messages
 *
 * This contains all the text for the Authorization container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Authorization';

export default defineMessages({
  firstname: {
    id: `${scope}.firstname`,
    defaultMessage: 'Firstname',
  },
  lastname: {
    id: `${scope}.lastname`,
    defaultMessage: 'Lastname',
  },
  firstnameRule: {
    id: `${scope}.firstnameRule`,
    defaultMessage: 'Please enter first name',
  },
  lastnameRule: {
    id: `${scope}.lastnameRule`,
    defaultMessage: 'Please enter last name',
  },
  welcome: {
    id: `${scope}.welcome`,
    defaultMessage: 'Welcome',
  },
  welcomeTo: {
    id: `${scope}.welcomeTo`,
    defaultMessage: 'Welcome to FEIDEKAI',
  },
  newUserText: {
    id: `${scope}.newUserText`,
    defaultMessage: 'New User？',
  },
  sendCodeText: {
    id: `${scope}.sendCodeText`,
    defaultMessage: 'SEND',
  },
  invitationCodeHelp: {
    id: `${scope}.invitationCodeHelp`,
    defaultMessage: 'What is it?',
  },
  cantReceiveSmsCode: {
    id: `${scope}.cantReceiveSmsCode`,
    defaultMessage: 'Could not receive verification code?',
  },
  logIn: {
    id: `${scope}.logIn`,
    defaultMessage: 'Log In',
  },
  createAccount: {
    id: `${scope}.createAccount`,
    defaultMessage: 'Create Account',
  },
  signUp: {
    id: `${scope}.signUp`,
    defaultMessage: 'SIGN UP',
  },
  password: {
    id: `${scope}.password`,
    defaultMessage: 'Password',
  },
  enterCode: {
    id: `${scope}.enterCode`,
    defaultMessage: 'Enter the verification code',
  },
  codeSent: {
    id: `${scope}.codeSent`,
    defaultMessage: 'Verification code has been sent to your phone',
  },
  logInWithOTP: {
    id: `${scope}.logInWithOTP`,
    defaultMessage: 'Log in with dynamic password instead',
  },
  logInWithPassword: {
    id: `${scope}.logInWithPassword`,
    defaultMessage: 'Log in with password instead',
  },
  forgotPassword: {
    id: `${scope}.forgotPassword`,
    defaultMessage: 'Forgot password?',
  },
  resetPassword: {
    id: `${scope}.resetPassword`,
    defaultMessage: 'Reset Password',
  },
  confirmPassword: {
    id: `${scope}.confirmPassword`,
    defaultMessage: 'Confirm Password',
  },
  backToLogin: {
    id: `${scope}.backToLogin`,
    defaultMessage: 'Back to Login',
  },
  referralCode: {
    id: `${scope}.referralCode`,
    defaultMessage: 'FitLine Referral Code',
  },
  enrollingExplanation: {
    id: `${scope}.enrollingExplanation`,
    defaultMessage:
      'FitLine is a referral system. Before you can register for an account you need a referrer ID.',
  },
  updateAddress: {
    id: `${scope}.updateAddress`,
    defaultMessage: 'Update Address',
  },
  useAddress: {
    id: `${scope}.useAddress`,
    defaultMessage: 'Save and Use',
  },
  addAddress: {
    id: `${scope}.addAddress`,
    defaultMessage: 'Add Address',
  },
  saveAddress: {
    id: `${scope}.saveAddress`,
    defaultMessage: 'Save Address',
  },
  updateFapiao: {
    id: `${scope}.updateFapiao`,
    defaultMessage: 'Update Invoice',
  },
  useFapiao: {
    id: `${scope}.useFapiao`,
    defaultMessage: 'Save and Use',
  },
  addFapiao: {
    id: `${scope}.addFapiao`,
    defaultMessage: 'Add Invoice',
  },
  saveFapiao: {
    id: `${scope}.saveFapiao`,
    defaultMessage: 'Save Invoice',
  },
  importAddressFromWechat: {
    id: `${scope}.importAddressFromWechat`,
    defaultMessage: 'Import address from wechat',
  },
  modalConfirmPay: {
    id: `${scope}.modalConfirmPay`,
    defaultMessage: 'Confirm that the information is consistent and pay',
  },
  next: {
    id: `${scope}.next`,
    defaultMessage: 'Next',
  },
  chooseCoupon: {
    id: `${scope}.chooseCoupon`,
    defaultMessage: 'No coupons selected, {quantity} available',
  },
  modifyCoupon: {
    id: `${scope}.modifyCoupon`,
    defaultMessage: 'Modify coupon',
  },
  edit: {
    id: `${scope}.edit`,
    defaultMessage: 'Edit',
  },
  deleteAddress: {
    id: `${scope}.deleteAddress`,
    defaultMessage: 'Delete Address',
  },
  deleteFapiao: {
    id: `${scope}.deleteFapiao`,
    defaultMessage: 'Delete Invoice',
  },
  confirm: {
    id: `${scope}.confirm`,
    defaultMessage: 'Confirm',
  },
  selectAddress: {
    id: `${scope}.selectAddress`,
    defaultMessage: 'Select',
  },
  selectFapiao: {
    id: `${scope}.selectFapiao`,
    defaultMessage: 'Select',
  },
  modalNotSure: {
    id: `${scope}.modalNotSure`,
    defaultMessage: 'Not sure, check back',
  },
  confirmation: {
    id: `${scope}.confirmation`,
    defaultMessage: 'Confirmation',
  },
  updateEmail: {
    id: `${scope}.updateEmail`,
    defaultMessage: 'Update Information',
  },
  resetPasswordAssistant: {
    id: `${scope}.resetPasswordAssistant`,
    defaultMessage: 'Request to reset password',
  },
  resetPasswordTepOne: {
    id: `${scope}.resetPasswordTepOne`,
    defaultMessage: 'Verify mobile phone number',
  },
  inputNewPassword: {
    id: `${scope}.inputNewPassword`,
    defaultMessage: 'Go to enter the new password',
  },
  resetComplete: {
    id: `${scope}.resetComplete`,
    defaultMessage: 'Reset Complete',
  },
  firstClauseStatement: {
    id: `${scope}.firstClauseStatement`,
    defaultMessage: 'I agree to the ',
  },
  lastClauseStatement: {
    id: `${scope}.lastClauseStatement`,
    defaultMessage: 'Terms and statements',
  },
  newPreferredCustomer: {
    id: `${scope}.newPreferredCustomer`,
    defaultMessage: 'New Preferred Customer, Hello!',
  },
  inputInvitationCode: {
    id: `${scope}.inputInvitationCode`,
    defaultMessage: 'Invitation code',
  },
  inputPhoneNumber: {
    id: `${scope}.inputPhoneNumber`,
    defaultMessage: 'Please enter your mobile phone number',
  },
  inputCorrectPhoneNumber: {
    id: `${scope}.inputCorrectPhoneNumber`,
    defaultMessage: '11 digit mobile phone number',
  },
  inputPassword: {
    id: `${scope}.inputPassword`,
    defaultMessage: 'Please input a password',
  },
  selectPackageGoods: {
    id: `${scope}.selectPackageGoods`,
    defaultMessage: 'Select a package goods',
  },
  selectOnePackageGoods: {
    id: `${scope}.selectOnePackageGoods`,
    defaultMessage: 'Choose a suit',
  },
  welcomeFC: {
    id: `${scope}.welcomeFC`,
    defaultMessage: 'Welcome Back, Franchisee',
  },
  FCLoginId: {
    id: `${scope}.FCLoginId`,
    defaultMessage: 'Franchisee ID',
  },
  inputFCLoginId: {
    id: `${scope}.inputFCLoginId`,
    defaultMessage: 'Please enter an eight digit your Franchisee ID',
  },
  goBack: {
    id: `${scope}.goBack`,
    defaultMessage: 'Go Back',
  },
  fourVerificationCode: {
    id: `${scope}.fourVerificationCode`,
    defaultMessage: 'Verification code',
  },
  formPageHeadTop: {
    id: `${scope}.formPageHeadTop`,
    defaultMessage: "Don't worry",
  },
  formPageHeadBottom: {
    id: `${scope}.formPageHeadBottom`,
    defaultMessage: 'You can set a new password',
  },
  clickLogin: {
    id: `${scope}.clickLogin`,
    defaultMessage: 'Click here to log in',
  },
  inputNowNewPassword: {
    id: `${scope}.inputNowNewPassword`,
    defaultMessage: 'Now enter your new password',
  },
  goToBuy: {
    id: `${scope}.goToBuy`,
    defaultMessage: 'PLACE AN ORDER',
  },
  aboutInvitationCode: {
    id: `${scope}.aboutInvitationCode`,
    defaultMessage: 'About Invitation Code',
  },
  timeAfter: {
    id: `${scope}.timeAfter`,
    defaultMessage: 'Resend in {time} seconds',
  },
  editShippingAddress: {
    id: `${scope}.editShippingAddress`,
    defaultMessage: 'Edit shipping address',
  },
  dialogTitle: {
    id: `${scope}.dialogTitle`,
    defaultMessage: 'FEIDEKAI Invitation',
  },
  dialogCaption: {
    id: `${scope}.dialogCaption`,
    defaultMessage:
      'Feidekai is a recommendation system. You need a recommender. After entering the ID of the recommender, you can register your account',
  },
  dialogAssistantMsg: {
    id: `${scope}.dialogAssistantMsg`,
    defaultMessage: 'Enter Recommender ID',
  },
  dialogConfirmMsg: {
    id: `${scope}.dialogConfirmMsg`,
    defaultMessage: 'No Recommender',
  },
  min18Age: {
    id: `${scope}.min18Age`,
    defaultMessage: 'I confirm that I am over 18 years old',
  },
  registrationAgreement: {
    id: `${scope}.registrationAgreement`,
    defaultMessage: 'User Agreement',
  },
  firstClauseStatementTwo: {
    id: `${scope}.firstClauseStatementTwo`,
    defaultMessage: 'I have read and agree',
  },
  passwordVerificationPrompt: {
    id: `${scope}.passwordVerificationPrompt`,
    defaultMessage: 'Inconsistent password entered twice',
  },
  passwordValidate: {
    id: `${scope}.passwordValidate`,
    defaultMessage: 'Password strength does not meet requirements',
  },
  clickRegisterAccount: {
    id: `${scope}.clickRegisterAccount`,
    defaultMessage: 'Click here to register an account',
  },
  passwordsInconsistent: {
    id: `${scope}.passwordsInconsistent`,
    defaultMessage: 'The two password inputs are inconsistent',
  },
});
