/* eslint-disable */
export const GET_CMS = (id) => `
{
  cmsBlocks(identifiers: "${id}") {
    items {
      identifier
      title
      content
    }
  }
}
`;
