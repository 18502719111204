/* eslint-disable no-new */
/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import { Suspense, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Helmet } from 'react-helmet-async';
import { Switch, Route } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/en';
import 'dayjs/locale/zh-cn';
import { SnackbarProvider } from 'notistack';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import SvgIcon from '@material-ui/core/SvgIcon';
import Hidden from '@material-ui/core/Hidden';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import Box from '@material-ui/core/Box';
import routes from 'utils/routes';
import { DEFAULT_STORE_NAME } from 'utils/constants';
import ScrollToTop from 'utils/RouterListener';
import MainNavigation from 'containers/App/components/MainNavigation';
import CloseNotification from 'utils/Notifications/CloseNotification';
// import WeChatCustomerService from 'components/WeChatCustomerService';
import Notifier from 'utils/Notifications';
import {
  AccountLogInPage,
  RegistrationPage,
  ResetPasswordPage,
  PartnerRegister,
} from 'containers/AuthorizationProvider/Loadable';
import Blog, { BlogDetailPage } from 'containers/Blog/Loadable';
import CatalogPage, { FlashSale, SearchResultPage } from 'containers/CatalogPage/Loadable';
import CheckoutPage, { PaymentPage } from 'containers/CheckoutPage/Loadable';
import CustomerService from 'containers/CustomerService/Loadable';
import CouponPage, { MyCouponPage } from 'containers/CouponPage/Loadable';
import HomePage from 'containers/HomePage/Loadable';
import NotFoundPage from 'containers/NotFoundPage/Loadable';
import OrderListPage from 'containers/OrderListPage/Loadable';
import OrderDetailPage, {
  OrderSuccessPage,
  OfflineOrderSuccessPage,
} from 'containers/OrderDetailPage/Loadable';
import ProductPage from 'containers/ProductPage/Loadable';
import RefundDetail, {
  OrderRefundApplicationPage,
  OrderRefundCourierPage,
} from 'containers/RefundPage/Loadable';
import SharingList, { CustomizeSharing } from 'containers/SharingProvider/Loadable';
import SMLogin from 'containers/SmLogin/Loadable';
import SmCustomer from 'containers/SmCustomer/Loadable';
import SmInvoice from 'containers/SmInvoice/Loadable';
import MyAccount from 'containers/MyAccount/Loadable';
import UserProfile from 'containers/UserProfile/Loadable';
import { FCMaterial } from 'containers/FCRegister/Loadable';
import AddressBookPage from 'containers/AddressBookPage/Loadable';
import FapiaoPage, {
  OrderInvoice,
  InvoiceApplication,
  InvoiceRecords,
  InvoiceApplicationSuccess,
  InvoiceDetail,
} from 'containers/FapiaoPage/Loadable';
import WishlistPage from 'containers/WishlistPage/Loadable';
import { selectMPLoginVisible } from 'containers/App/slices';
import VerifyAuthentication from 'utils/Auth/VerifyAuthentication';
import RequireAuthentication from 'utils/Auth/RequireAuthentication';
import { InfoCircleIcon, CheckIcon, ExclamationCircleIcon } from 'components/Icons';
// import MembershipProvider from 'containers/MembershipProvider/Loadable';
import ShoppingCart from 'containers/ShoppingCart/Loadable';
import Footer from 'components/Footer/Loadable';
import Header from 'components/Header/Loadable';
import useLogin from 'utils/Hooks/useLogin';
import useWechatJSSDK from 'utils/Hooks/useWechatJSSDK';
import useInitApplication from 'utils/Hooks/useInitApplication';
import useCheckoutShoppingList from 'utils/Hooks/useCheckoutShoppingList';
import useGetInviteId from 'containers/AuthorizationProvider/hooks/useGetInviteId';
import StoreProvider from 'containers/StoreProvider';
import RMA from 'containers/RMA/Loadable';
import OldIDRegister from 'containers/OldIDRegister/Loadable';
import JoinUs from 'containers/JoinUs/Loadable';
import {
  DestroyAccount,
  ExportAccount,
  Privacy,
  PrivacyPolicy,
} from 'containers/PrivacyCenter/Loadable';
import FirstLoading from 'containers/App/components/FirstLoading';
import AnimateComponent from 'utils/Auth/AnimateComponent';
import OfflineDetection from 'components/OfflineDetection';
import { snackbarStyles, AppWrapper } from 'containers/App/styles/index.styles';
import Icons from 'containers/Icons/Loadable';
import useWechatToWebview from 'utils/Hooks/useWechatToWebview';
import FirstEntry from 'containers/App/components/FirstEntry';
import determineEnvironment from 'utils/WeChat/determineEnvironment';
// eslint-disable-next-line no-new
// eslint-disable-next-line new-cap
// Set up DayJS - Date Formatting
dayjs.locale('en');
dayjs.locale('zh-cn');
dayjs.extend(LocalizedFormat);
dayjs.extend(utc);

const VerifiedLogInPage = VerifyAuthentication(AccountLogInPage);
const VerifiedResetPasswordPage = VerifyAuthentication(ResetPasswordPage);
const VerifiedRegistrationPage = VerifyAuthentication(RegistrationPage);
const VerifiedIDRegistrationPage = VerifyAuthentication(OldIDRegister);

const ProtectedAddressBookPage = RequireAuthentication(AddressBookPage);
const ProtectedFapiaoPage = RequireAuthentication(FapiaoPage);
const ProtectedWishlistPage = RequireAuthentication(WishlistPage);
const ProtectedCheckoutPage = RequireAuthentication(CheckoutPage);
const ProtectedPaymentPage = RequireAuthentication(PaymentPage);
const ProtectedDashboardPage = RequireAuthentication(MyAccount);
const ProtectedUserProfile = RequireAuthentication(UserProfile);
const ProtectedOrderSuccessPage = RequireAuthentication(OrderSuccessPage);
const ProtectedOfflineOrderSuccessPage = RequireAuthentication(OfflineOrderSuccessPage);
const ProtectedMyCouponPage = RequireAuthentication(MyCouponPage);
const ProtectedOrderListPage = RequireAuthentication(OrderListPage);
const ProtectedOrderDetailPage = RequireAuthentication(OrderDetailPage);
const ProtectedOrderRefundApplication = RequireAuthentication(OrderRefundApplicationPage);
const ProtectedOrderRefundCourier = RequireAuthentication(OrderRefundCourierPage);
const ProtectedRefundDetail = RequireAuthentication(RefundDetail);
const ProtectedSharingList = RequireAuthentication(SharingList);
const ProtectedCustomizeSharing = RequireAuthentication(CustomizeSharing);
// const ProtectedFCRegister = RequireAuthentication(FCRegister);
const ProtectedFCMaterial = RequireAuthentication(FCMaterial);
// const ProtectedMembershipProvider = RequireAuthentication(MembershipProvider);
const ProtectedOrderInvoice = RequireAuthentication(OrderInvoice);
const ProtectedRMA = RequireAuthentication(RMA);
const ProtectedInvoiceRecords = RequireAuthentication(InvoiceRecords);
const ProtectedInvoiceApplication = RequireAuthentication(InvoiceApplication);
const ProtectedInvoiceApplicationSuccess = RequireAuthentication(InvoiceApplicationSuccess);
const ProtectedInvoiceDetail = RequireAuthentication(InvoiceDetail);

const AnimateCatalogPage = AnimateComponent(CatalogPage);
const AnimateBlogDetailPage = AnimateComponent(BlogDetailPage);
const AnimateHomePage = AnimateComponent(HomePage);
const AnimateBlogPage = AnimateComponent(Blog);
const AnimateFoundPage = AnimateComponent(NotFoundPage);
const AnimateCMSPage = AnimateComponent(CustomerService);
const AnimateProductPage = AnimateComponent(ProductPage);
const AnimateJoinUsPage = AnimateComponent(JoinUs);
const AnimatePrivacyPage = AnimateComponent(Privacy);
const AnimatePrivacyPolicyPage = AnimateComponent(PrivacyPolicy);
const AnimateExportAccountPage = AnimateComponent(ExportAccount);
const AnimateDestroyAccountPage = AnimateComponent(DestroyAccount);
const AnimatePartnerRegisterPage = AnimateComponent(PartnerRegister);
const AnimateSearchResultPage = AnimateComponent(SearchResultPage);
const AnimateShoppingCart = AnimateComponent(ShoppingCart);

function App({ classes }) {
  const [navigateToLogin] = useLogin();
  // const postLogin = usePostLogin();
  useWechatJSSDK();
  useWechatToWebview();
  const initApp = useInitApplication();
  const { checkoutShoppingList } = useCheckoutShoppingList();
  const MPLoginVisible = useSelector(selectMPLoginVisible);
  const { sessionId } = useGetInviteId();
  const { hash } = window.location;

  const [isFirstEntry, setIsFirstEntry] = useState(
    JSON.parse(localStorage.getItem('isFirstEntry')) || true,
  );

  useEffect(() => {
    if (MPLoginVisible) {
      navigateToLogin();
    }
  }, [MPLoginVisible]);

  const setUpApp = () => {
    if (sessionId) {
      document.cookie = `${sessionId}; Max-Age=86400; path=/; domain=.tmogroup.asia;`;
    }

    initApp().then(() => {
      const [type, qrcodeId, customerId] = hash.split('-');
      if (type === '#shoppingList' && !isEmpty(qrcodeId) && !isEmpty(customerId)) {
        checkoutShoppingList({
          qrcodeId,
          customerId,
        });
      }
    });
  };

  useEffect(() => {
    // Check for token from MP
    // const search = get(window.location, 'search', '');
    // const token = getUrlParameter(search, 'token');

    // if (!isNull(token) && token !== '') {
    //   postLogin(token).then(() => setUpApp());
    // } else {
    //   setUpApp();
    // }
    setUpApp();
  }, []);

  const snackbarAction = key => <CloseNotification notificationKey={key} />;

  const handleAgree = () => setIsFirstEntry(false);

  return (
    <Box>
      {isFirstEntry && determineEnvironment() && (
        <FirstEntry isFirstEntry={isFirstEntry} onAgree={handleAgree} />
      )}
      <AppWrapper>
        <SnackbarProvider
          iconVariant={{
            default: <SvgIcon component={InfoCircleIcon} viewBox="0 0 16 16" />,
            success: <SvgIcon component={CheckIcon} viewBox="0 0 16 16" />,
            error: <SvgIcon component={ExclamationCircleIcon} viewBox="0 0 16 16" />,
            warning: <SvgIcon component={ExclamationCircleIcon} viewBox="0 0 16 16" />,
            info: <SvgIcon component={InfoCircleIcon} viewBox="0 0 16 16" />,
          }}
          classes={{
            root: classes.root,
            message: classes.message,
            action: classes.action,
            contentRoot: classes.contentRoot,
            variantInfo: clsx([classes.info]),
            variantWarning: clsx([classes.warning]),
            variantSuccess: clsx([classes.success]),
            variantError: clsx([classes.error]),
          }}
          action={snackbarAction}
          dense
          preventDuplicate
          maxSnack={1}
        >
          <Suspense fallback={<FirstLoading loading />}>
            <ScrollToTop />
            <CssBaseline />
            {/* <WeChatCustomerService /> */}

            <Helmet titleTemplate={`%s - ${DEFAULT_STORE_NAME}`} defaultTitle={DEFAULT_STORE_NAME}>
              <meta name="description" content={DEFAULT_STORE_NAME} />
            </Helmet>
            <OfflineDetection />
            <Hidden smDown>
              <Header />
            </Hidden>
            <StoreProvider />
            {/* <HeadLogin /> */}
            {/* {loading && <PageLoader loading />} */}
            <Switch>
              <Route path={routes.blogDetailPage()} />
              <Route exact path={routes.checkout()} />
              <Route exact path={routes.pay} />
              <Route path={routes.coupons} />
              <Route path={routes.myCoupons()} />
              <Route path={routes.myCouponPage} />
              <Route path={routes.orderInvoice()} />
              <Route path={routes.orderDetail()} />
              <Route path={routes.orders()} />
              <Route path={routes.ordersAll} />
              <Route path={routes.product()} />
              <Route path={routes.refundApplication} />
              <Route path={routes.refundDelivery} />
              <Route path={routes.refundDetail} />
              <Route path={routes.share} />
              <Route path={routes.sharingList} />
              <Route path={routes.userProfile} />
              <Route path={routes.registration()} />
              <Route exact path={routes.registerPartner()} />
              <Route path={routes.logIn} />
              <Route path={routes.forgotPassword} />
              <Route path={routes.search()} />
              {/* <Route path={routes.membership} /> */}
              <Route path={routes.shoppingCart} />
              <Route path={routes.idRegister} />
              <Route path={routes.cms()} />
              <Route path={routes.FCMaterial()} />
              <Route path={routes.joinUs} />
              <Route exact path={routes.privacy} />
              <Route exact path={routes.privacyPolicy} />
              <Route exact path={routes.exportAccount} />
              <Route exact path={routes.destroyAccount} />
              <Route exact path={routes.success()} />
              <Route exact path={routes.offlineSuccess()} />
              <Route exact path={routes.rma} />
              <Route exact path={routes.invoiceApplication()} />
              <Route exact path={routes.invoiceRecords} />
              <Route exact path={routes.applyInvoiceSuccess()} />
              <Route exact path={routes.icons} />
              <Route exact path={routes.invoiceDetail()} />
              <Route path="" component={MainNavigation} />
            </Switch>

            <Switch>
              <Route exact path={routes.SMLogin} component={SMLogin} />
              <Route exact path={routes.SmCustomer} component={SmCustomer} />
              <Route exact path={routes.SmInvoice} component={SmInvoice} />
              <Route path={routes.addressBook} component={ProtectedAddressBookPage} />
              <Route path={routes.fapiao} component={ProtectedFapiaoPage} />
              <Route path={routes.wishlist} component={ProtectedWishlistPage} />
              <Route exact path={routes.checkout()} component={ProtectedCheckoutPage} />
              <Route exact path={routes.pay} component={ProtectedPaymentPage} />
              <Route path={routes.coupons} component={CouponPage} />
              <Route exact path={routes.dashboard} component={ProtectedDashboardPage} />
              <Route path={routes.userProfile} component={ProtectedUserProfile} />
              <Route path={routes.forgotPassword} component={VerifiedResetPasswordPage} />
              <Route exact path={routes.flashsale} component={FlashSale} />
              <Route exact path={routes.logIn} component={VerifiedLogInPage} />
              <Route
                path={[routes.myCoupons(), routes.myCouponPage]}
                component={ProtectedMyCouponPage}
              />
              <Route
                path={[routes.orders(), routes.ordersAll]}
                component={ProtectedOrderListPage}
              />
              <Route path={routes.orderInvoice()} exact component={ProtectedOrderInvoice} />
              <Route path={routes.orderDetail()} exact component={ProtectedOrderDetailPage} />
              <Route path={routes.registration()} component={VerifiedRegistrationPage} />
              <Route path={routes.refundApplication} component={ProtectedOrderRefundApplication} />
              <Route path={routes.refundDelivery} component={ProtectedOrderRefundCourier} />
              <Route path={routes.refundDetail} component={ProtectedRefundDetail} />
              <Route path={routes.share} component={ProtectedCustomizeSharing} />
              <Route path={routes.sharingList} component={ProtectedSharingList} />
              <Route path={routes.success()} component={ProtectedOrderSuccessPage} />
              <Route path={routes.offlineSuccess()} component={ProtectedOfflineOrderSuccessPage} />
              {/* <Route exact path={routes.FCRegister} component={ProtectedFCRegister} /> */}
              <Route exact path={routes.FCMaterial()} component={ProtectedFCMaterial} />
              {/* <Route path={routes.membership} component={ProtectedMembershipProvider} /> */}
              <Route exact path={routes.shoppingCart} component={AnimateShoppingCart} />
              <Route path={routes.idRegister} component={VerifiedIDRegistrationPage} />
              <Route exact path={routes.blog} component={AnimateBlogPage} />
              <Route exact path={routes.catalogEmpty} component={AnimateCatalogPage} />
              <Route path={routes.product()} component={AnimateProductPage} />
              <Route
                path={routes.catalogSpecial()}
                render={props => <AnimateCatalogPage {...props} specialCategory />}
              />
              <Route exact path={routes.home} component={AnimateHomePage} />
              <Route path={routes.cms()} component={AnimateCMSPage} />
              <Route exact path={routes.blogDetailPage()} component={AnimateBlogDetailPage} />
              <Route path={routes.catalog()} component={AnimateCatalogPage} />
              <Route exact path={routes.registerPartner()} component={AnimatePartnerRegisterPage} />
              <Route path={routes.search()} component={AnimateSearchResultPage} />
              <Route path={routes.joinUs} component={AnimateJoinUsPage} />
              <Route exact path={routes.privacy} component={AnimatePrivacyPage} />
              <Route exact path={routes.privacyPolicy} component={AnimatePrivacyPolicyPage} />
              <Route exact path={routes.exportAccount} component={AnimateExportAccountPage} />
              <Route exact path={routes.destroyAccount} component={AnimateDestroyAccountPage} />
              <Route exact path={routes.rma} component={ProtectedRMA} />
              <Route
                exact
                path={routes.invoiceApplication()}
                component={ProtectedInvoiceApplication}
              />
              <Route exact path={routes.invoiceRecords} component={ProtectedInvoiceRecords} />
              <Route
                exact
                path={routes.applyInvoiceSuccess()}
                component={ProtectedInvoiceApplicationSuccess}
              />
              <Route exact path={routes.invoiceDetail()} component={ProtectedInvoiceDetail} />
              <Route exact path={routes.icons} component={Icons} />
              <Route path={routes.notFound} component={AnimateFoundPage} />
            </Switch>
            <Notifier />
          </Suspense>
        </SnackbarProvider>
      </AppWrapper>
      <Hidden smDown>
        <motion.div
          initial={{ y: 100, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ delay: 0.2, duration: 2, type: 'spring', stiffness: 100 }}
        >
          <Footer />
        </motion.div>
      </Hidden>
    </Box>
  );
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withStyles(snackbarStyles))(App);
